import { ProtectedRoute } from 'components/common/ProtectedRoute'
import AuthValidator from 'HOC/AuthValidator/AuthValidator'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import { DoctorsPage } from 'pages/DoctorsPage'
import { LoginPage } from 'pages/LoginPage'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <AuthValidator>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute outlet={<DashboardLayout />} />}
          >
            <Route path="doctors" element={<DoctorsPage />} />
          </Route>
        </Routes>
      </AuthValidator>
    </BrowserRouter>
  )
}

export default App
