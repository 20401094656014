import { Stack } from '@mui/material'
import { DataGrid, GridColDef, GridValidRowModel } from '@mui/x-data-grid'
import { useState } from 'react'
import { DoctorSpeciality } from 'types/Doctor/DoctorInterface'
import { ActionButtons, ActionButtonsProps } from './components/ActionButtons'
import { SearchBar } from './components/SearchBar'
import { normalizeRows } from './utils/normalizeRows'

interface DataTableProps {
  keyId: string
  rows: readonly GridValidRowModel[]
  columns: GridColDef[]
  hideActionButtons?: boolean
  actionButtonsOptions?: Omit<ActionButtonsProps, 'row'>
  isLoading: boolean
}

const DataTable = ({
  isLoading,
  columns,
  rows,
  hideActionButtons,
  actionButtonsOptions,
  keyId,
}: DataTableProps) => {
  const ACTIONS_COLUMN: GridColDef = {
    headerName: 'Acciones',
    headerAlign: 'center',
    align: 'center',
    field: 'actions',
    filterable: false,
    sortable: false,
    flex: 1,
    renderCell: (row) => <ActionButtons row={row} {...actionButtonsOptions} />,
  }

  const [searchValue, setSearchValue] = useState<string>('')
  const normalizedRows = normalizeRows(rows as [], keyId)

  // TODO: Fix these rows normalization in order to make generic
  const ROWS = normalizedRows.filter((row: any) => {
    const fullName = `${row.nombre} ${row.apellido_1} ${row.apellido_2}`
    const specialities = row.doctor_speciality
      .map((value: DoctorSpeciality) => value.speciality?.nombre.toLowerCase())
      .join(' ')

    return (
      fullName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      specialities.includes(searchValue?.toLowerCase())
    )
  })
  const COLUMNS = !hideActionButtons ? [...columns, ACTIONS_COLUMN] : columns

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <SearchBar
        value={searchValue}
        handleChange={(event) => setSearchValue(event.target.value)}
      />
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
            color: 'primary.main',
          },
          '& .MuiDataGrid-columnSeparator': { visibility: 'hidden' },
        }}
        loading={isLoading}
        columns={COLUMNS}
        rows={ROWS}
        pageSize={6}
        disableColumnFilter
        disableSelectionOnClick
        disableColumnSelector
        disableColumnMenu
        disableDensitySelector
      />
    </Stack>
  )
}

export default DataTable
