import { useQuery } from '@tanstack/react-query'
import { getUserData } from 'api/authApi'

const useAuth = () => {
  try {
    const { isLoading, isSuccess } = useQuery(["user-data"], getUserData, {
      useErrorBoundary: true,
      retry: false,
    })
    return { isAuth: true, isLoading, isSuccess }
  } catch (error) {
    return { isAuth: false }
  }
}

export { useAuth }
