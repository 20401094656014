import { ENV } from 'env'
import { UserInterface } from 'types/User/UserInterface'
import { api } from './api'

const CSRF_TOKEN_ENDPOINT = '/sanctum/csrf-cookie'
const LOGIN_ENDPOINT = '/login'
const PROFILE_ENDPOINT = '/me'

interface CredentialsInterface {
  email: string
  password: string
}

interface LoginResponseInterface {
  token: string
  user: UserInterface
  message: string
  ok: boolean
}

const submitLogin = async (credentials: CredentialsInterface) =>
  <LoginResponseInterface>(await api.post(LOGIN_ENDPOINT, credentials)).data

interface ProfileEndpointResponseInterface {
  ok: boolean
  user: UserInterface
  message: string
}

const getUserData = async () =>
  <ProfileEndpointResponseInterface>(await api.get(PROFILE_ENDPOINT)).data

const getCSRFToken = async () =>
  (await api.get(CSRF_TOKEN_ENDPOINT, { baseURL: ENV })).data

export { submitLogin, getCSRFToken, getUserData }
