import { useAuth } from 'hooks/useAuth'
import { Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
  outlet: JSX.Element
}

const ProtectedRoute = ({ outlet }: ProtectedRouteProps) => {
  const isAuth = useAuth()
  return isAuth.isAuth ? outlet : <Navigate to="/login" />
  // return outlet
}
export default ProtectedRoute
