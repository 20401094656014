import { SpecialityInterface } from 'types/Speciality/SpecialityInterface'
import { api } from './api'

const SPECIALITIES_ENDPOINT = '/specialities'

interface SpecialitiesResponseInterface {
  ok: boolean
  specialities: SpecialityInterface[]
  message: string
}
const getAllSpecialities = async () =>
  (await api.get<SpecialitiesResponseInterface>(SPECIALITIES_ENDPOINT)).data

export type { SpecialitiesResponseInterface }
export { getAllSpecialities }
