import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { EmailOutlined, KeyOutlined } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { submitLogin } from 'api/authApi'
import { useNavigate } from 'react-router-dom'
import { useCSRFToken } from 'hooks/useCSRFToken'

interface LoginFormState {
  email: string
  password: string
}

const LoginPage = () => {
  useCSRFToken()

  const { register, handleSubmit } = useForm<LoginFormState>()
  const hideImage = useMediaQuery('(max-width: 1200px)')
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    (data: LoginFormState) => submitLogin(data),
    {
      onSuccess: ({ token, user }) => {
        queryClient.setQueryData(['user-data'], user)
        localStorage.setItem('user-token', token)
        navigate('/dashboard/doctors')
      },
    }
  )

  const onSubmit = async (data: LoginFormState) => {
    mutate(data)
  }

  return (
    <Grid container sx={{ width: '100%', height: '100%' }}>
      {!hideImage && (
        <Grid item lg={6} xl={4} sx={{ height: '100%', overflow: 'hidden' }}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <img src="images/landingImage.jpg" alt="random" />
          </Box>
        </Grid>
      )}

      <Grid item xs={12} lg={6} xl={8} sx={{ height: '100%' }}>
        <Box
          sx={{
            backgroundColor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Card
            sx={{
              padding: '2rem',
              width: '40%',
              minWidth: '24rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Box>
              <Typography
                variant="h1"
                sx={{ fontSize: '2rem', textAlign: 'center' }}
              >
                Bienvenido!
              </Typography>
            </Box>
            <CardContent>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <Icon sx={{ paddingRight: '2.25rem' }} color="disabled">
                        <EmailOutlined />
                      </Icon>
                    ),
                  }}
                  type="text"
                  variant="outlined"
                  label="Correo electrónico"
                  placeholder="johndoe@example.com"
                  autoComplete="off"
                  {...register('email')}
                />

                <TextField
                  InputProps={{
                    startAdornment: (
                      <Icon sx={{ paddingRight: '2.25rem' }} color="disabled">
                        <KeyOutlined />
                      </Icon>
                    ),
                  }}
                  type="password"
                  variant="outlined"
                  label="Contraseña"
                  placeholder="********"
                  {...register('password')}
                />

                <Button variant="contained" size="large" type="submit">
                  {isLoading ? 'Cargando...' : 'Iniciar sesión'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}
export default LoginPage
