import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ENV } from 'env'

axios.defaults.withCredentials = true

const API_LAYER_CONFIG: AxiosRequestConfig = {
  baseURL: `${ENV}/api`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE',
    'Access-Control-Allow-Credentials': 'True',
    'Authorization': `Bearer ${localStorage.getItem("user-token")}`,
    'Accept': 'application/json',
  },
}

const axiosInstance = axios.create(API_LAYER_CONFIG)

const apiMethods = (axios: AxiosInstance) => {
  return {
    get: <T>(url: string, config: AxiosRequestConfig = {}) => {
      return axios.get<T>(url, config)
    },
    post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => {
      return axios.post<T>(url, body, config)
    },
    put: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => {
      return axios.put<T>(url, body, config)
    },
    delete: <T>(url: string, config: AxiosRequestConfig = {}) => {
      return axios.delete<T>(url, config)
    },
  }
}

const api = apiMethods(axiosInstance)

export { api }
