import { AppBar, Box, IconButton, Toolbar } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { MenuOutlined as MenuIcon } from '@mui/icons-material'

const DashboardLayout = () => {
  return (
    <Box sx={{ height: '100%', width: '100%', backgroundColor: '#f2f2f2' }}>
      <AppBar position="static" sx={{ paddingBlock: '0.35rem' }}>
        <Toolbar>
          <IconButton>
            <MenuIcon color="inherit" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          padding: '3rem 6rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}
export default DashboardLayout
