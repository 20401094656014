import {
  UpsertDoctorInterface,
  DoctorInterface,
} from 'types/Doctor/DoctorInterface'
import { api } from './api'

const DOCTORS_ENDPOINT = '/doctors'

interface GetDoctorsResponseInterface {
  ok: true
  message: string
  doctors: DoctorInterface[]
}

const getAllDoctors = async () =>
  <GetDoctorsResponseInterface>(await api.get(DOCTORS_ENDPOINT)).data

interface UpsertDoctorResponseInterface {
  ok: true
  message: string
  doctor: DoctorInterface
}

const createDoctor = async (data: UpsertDoctorInterface) =>
  <UpsertDoctorResponseInterface>(await api.post(DOCTORS_ENDPOINT, data)).data

const updateDoctor = async (data: UpsertDoctorInterface, doctorId: number) =>
  <UpsertDoctorResponseInterface>(
    (await api.post(`${DOCTORS_ENDPOINT}/${doctorId}`, data)).data
  )

const deleteDoctor = async (doctorId: number) =>
  (await api.delete(`${DOCTORS_ENDPOINT}/${doctorId}`)).data

export { getAllDoctors, createDoctor, updateDoctor, deleteDoctor }
