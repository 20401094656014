import LoadingOverlay from 'components/common/LoadingOverlay/LoadingOverlay'
import { useAuth } from 'hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'

interface AuthValidatorProps {
  children: any
}

const AuthValidator = ({ children }: AuthValidatorProps) => {
  const { isAuth, isLoading } = useAuth()
  const { pathname } = useLocation()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (pathname === '/login' && !isAuth) {
    return children
  }

  return isAuth ? children : <Navigate to="/login" replace />
}
export default AuthValidator
