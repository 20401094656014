import { Stack } from '@mui/material'
import { IconButton } from 'components/common/IconButton'
import {
  EditRounded as EditIcon,
  DeleteRounded as DeleteIcon,
} from '@mui/icons-material'
import { GridCellParams } from '@mui/x-data-grid'

interface ActionButtonsProps {
  row: GridCellParams
  hideEdit?: boolean
  hideDelete?: boolean
  handleEdit?: (row: GridCellParams) => void
  handleDelete?: (row: GridCellParams) => void
  extraButtons?: JSX.Element[]
}

function ActionButtons({
  row,
  hideEdit,
  hideDelete,
  handleEdit,
  handleDelete,
  extraButtons,
}: ActionButtonsProps) {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%' }}
    >
      {!hideEdit && handleEdit && (
        <IconButton
          onClick={() => handleEdit(row)}
          tooltip="Editar"
          icon={<EditIcon color="primary" />}
        />
      )}
      {!hideDelete && handleDelete && (
        <IconButton
          onClick={() => handleDelete(row)}
          tooltip="Eliminar"
          icon={<DeleteIcon color="error" />}
        />
      )}
      {extraButtons}
    </Stack>
  )
}

export type { ActionButtonsProps }
export default ActionButtons
