import {
  Box,
  Button,
  Dialog,
  DialogProps,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { customAlphabet } from 'nanoid'
import { SpecialitiesResponseInterface } from 'api/specialitiesApi'
import { useForm } from 'react-hook-form'
import { createDoctor, updateDoctor } from 'api/doctorsApi'
import {
  UpsertDoctorInterface,
  DoctorInterface,
} from 'types/Doctor/DoctorInterface'
import { useEffect } from 'react'

interface DoctorModalProps extends DialogProps {
  open: boolean
  handleClose: () => void
  selectedDoctor?: DoctorInterface
}

interface DoctorModalFormState {
  doctorId?: number
  code: number
  prefix: string
  firstLastName: string
  secondLastName: string
  name: string
  phoneNumber: string
  email: string
  appointmentTime: number
  specialities: number[]
}

const DoctorModal = ({
  open,
  handleClose,
  selectedDoctor,
}: DoctorModalProps) => {
  const nanoid = customAlphabet('1234567890', 7)

  const INITIAL_FORM_STATE: Omit<DoctorModalFormState, 'code'> = {
    appointmentTime: Number(selectedDoctor?.duracion_citas) ?? 0,
    email: selectedDoctor?.correo ?? '',
    firstLastName: selectedDoctor?.apellido_1 ?? '',
    name: selectedDoctor?.nombre ?? '',
    phoneNumber: selectedDoctor?.telefono ?? '',
    prefix: selectedDoctor?.prefijo ?? 'Dr.',
    secondLastName: selectedDoctor?.apellido_2 ?? '',
    specialities:
      selectedDoctor?.doctor_speciality.map(
        ({ codigo_especialidad_fk }) => codigo_especialidad_fk
      ) ?? [],
  }

  const { register, handleSubmit, watch, reset } =
    useForm<DoctorModalFormState>()

  useEffect(() => {
    if (open) {
      const randomCode = nanoid()
      reset({
        ...INITIAL_FORM_STATE,
        doctorId: selectedDoctor?.doctor_id,
        code: selectedDoctor?.codigo ?? Number(randomCode),
      })
    }
  }, [selectedDoctor, open])

  const queryClient = useQueryClient()
  const { mutate: createDoctorMutation } = useMutation(
    (data: UpsertDoctorInterface) => createDoctor(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['doctors'])
      },
    }
  )

  const { mutate: updateDoctorMutation } = useMutation(
    (data: UpsertDoctorInterface) =>
      updateDoctor(data, data.doctorId as number),
    {
      onSuccess: () => {
        console.log('success')

        queryClient.invalidateQueries(['doctors'])
      },
    }
  )

  const { specialities } =
    queryClient.getQueryData<SpecialitiesResponseInterface>(['specialities']) ??
    {}

  const specialitiesSelectValue = watch('specialities', [])
  const prefixSelectValue = watch('prefix')

  const onClose = () => {
    reset()
    handleClose()
  }

  const onSubmit = (data: DoctorModalFormState) => {
    const {
      doctorId,
      code,
      prefix,
      firstLastName,
      secondLastName,
      name,
      appointmentTime,
      email,
      phoneNumber,
      specialities,
    } = data

    const upsertDoctorAdapter: UpsertDoctorInterface = {
      doctorId,
      codigo: Number(code),
      nombre: name.toUpperCase(),
      apellido_1: firstLastName.toUpperCase(),
      apellido_2: secondLastName.toUpperCase(),
      especialidades: specialities,
      duracion_citas: appointmentTime,
      correo: email,
      telefono: phoneNumber,
      prefijo: prefix,
      EMPRESA: 1,
    }

    console.log(upsertDoctorAdapter)

    selectedDoctor
      ? updateDoctorMutation(upsertDoctorAdapter)
      : createDoctorMutation(upsertDoctorAdapter)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
      <Box
        sx={{
          padding: '2rem 4rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',

        }}
      >
        <Box component="header">
          <Typography variant="h4">Profesional</Typography>
        </Box>

        <Grid
          container
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label="Código"
                variant="outlined"
                disabled
                {...register('code')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="prefix-select">Prefijo</InputLabel>
              <Select
                label="Prefijo"
                labelId="prefix-select"
                fullWidth
                variant="outlined"
                value={prefixSelectValue ?? 'Dr.'}
                {...register('prefix')}
              >
                <MenuItem value="Dr." selected>
                  Dr.
                </MenuItem>
                <MenuItem value="Dra.">Dra.</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label="Primer apellido"
                variant="outlined"
                {...register('firstLastName')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label="Segundo apellido"
                variant="outlined"
                {...register('secondLastName')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label="Nombre"
                variant="outlined"
                {...register('name')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label="Teléfono"
                variant="outlined"
                {...register('phoneNumber')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label="Correo electrónico"
                variant="outlined"
                {...register('email')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                label="Duración de la cita (en minutos)"
                variant="outlined"
                {...register('appointmentTime')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>

            <FormControl fullWidth>
              <InputLabel id="speciality-select">Especialidades</InputLabel>
              <Select
                label="Especialidades"
                labelId="speciality-select"
                fullWidth
                variant="outlined"
                multiple
                value={specialitiesSelectValue ?? []}
                {...register('specialities')}
              >
                {specialities?.map(({ especialidad_id, nombre }) => (
                  <MenuItem key={especialidad_id} value={especialidad_id}>
                    {nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Button
              sx={{ marginLeft: 'auto' }}
              variant="contained"
              size="large"
              type="submit"
            >
              {selectedDoctor ? 'Actualizar' : 'Agregar'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}
export default DoctorModal
