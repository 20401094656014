import { Box, Button, Card, Chip, Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteDoctor, getAllDoctors } from 'api/doctorsApi'
import { DataTable } from 'components/common/DataTable'
import { DoctorSpeciality, DoctorInterface } from 'types/Doctor/DoctorInterface'
import { AddOutlined as AddIcon } from '@mui/icons-material'
import { useBoolean } from 'hooks/useBoolean'
import DoctorModal from './components/DoctorModal/DoctorModal'
import { getAllSpecialities } from 'api/specialitiesApi'
import { useState } from 'react'

const DoctorsPage = () => {
  const [selectedDoctor, setSelectedDoctor] = useState<
    DoctorInterface | undefined
  >()
  const queryClient = useQueryClient()
  const { data: doctorsResponse, isLoading: isLoadingDoctors } = useQuery(
    ['doctors'],
    getAllDoctors
  )

  const { isLoading: isLoadingSpecialities } = useQuery(
    ['specialities'],
    getAllSpecialities
  )

  const { mutate: deleteDoctorMutation } = useMutation(
    (doctorId: number) => deleteDoctor(doctorId),
    {
      onSuccess: () => {
        console.log('Here')
        queryClient.invalidateQueries(['doctors'])
      },
    }
  )
  const COLUMNS: GridColDef[] = [
    {
      field: 'codigo',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      flex: 1,
    },

    {
      field: 'nombre',
      headerName: 'Nombre completo',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <span>
          {row.nombre} {row.apellido_1} {row.apellido_2}
        </span>
      ),
      flex: 2,
    },
    {
      field: 'doctor_speciality',
      headerName: 'Especialidades',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      flex: 3,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              gap: '0.75rem',
            }}
          >
            {value.length > 2 ? (
              <Tooltip
                title={value
                  .map(({ speciality }: DoctorSpeciality) => speciality.nombre)
                  .join(' -- ')}
              >
                <Box
                  sx={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}
                >
                  {value.slice(0, 2).map(
                    ({ speciality }: DoctorSpeciality) =>
                      speciality?.nombre && (
                        <Chip
                          sx={{
                            backgroundColor: 'primary.light',
                            color: 'white',
                            opacity: 0.7,
                          }}
                          variant="filled"
                          label={speciality?.nombre}
                        />
                      )
                  )}
                  <span>y {value.length - 2} más</span>
                </Box>
              </Tooltip>
            ) : (
              value.map(
                ({ speciality }: DoctorSpeciality) =>
                  speciality?.nombre && (
                    <Chip
                      sx={{
                        backgroundColor: 'primary.light',
                        color: 'white',
                        opacity: 0.7,
                      }}
                      variant="filled"
                      label={speciality?.nombre}
                    />
                  )
              )
            )}
          </Box>
        )
      },
    },
    {
      field: 'telefono',
      headerName: 'Teléfono',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'correo',
      headerName: 'Correo',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      flex: 2,
    },
  ]

  const {
    value: isOpenDoctorModal,
    setTrue: setOpenDoctorModal,
    setFalse: setCloseDoctorModal,
  } = useBoolean(false)

  const openDoctorModal = (targetDoctor?: DoctorInterface) => {
    if (targetDoctor) {
      setSelectedDoctor(targetDoctor)
    }

    setOpenDoctorModal()
  }

  const closeDoctorModal = () => {
    setSelectedDoctor(undefined)
    setCloseDoctorModal()
  }

  return (
    <Box
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
    >
      <Box component="header">
        <Typography
          variant="h1"
          sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}
        >
          Gestor de especialistas
        </Typography>
      </Box>

      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          height: '41.1rem',
          padding: '3rem',
        }}
      >
        <DataTable
          keyId="doctor_id"
          columns={COLUMNS}
          rows={
            doctorsResponse?.doctors.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            ) ?? []
          }
          isLoading={isLoadingDoctors || isLoadingSpecialities}
          actionButtonsOptions={{
            handleDelete: ({ id }) => deleteDoctorMutation(Number(id)),
            handleEdit: ({ row: targetDoctor }) =>
              openDoctorModal({ ...targetDoctor, doctor_id: targetDoctor.id }),
          }}
        />

        <Button
          onClick={() => openDoctorModal()}
          size="large"
          variant="contained"
          sx={{
            maxWidth: '18rem',
            marginLeft: 'auto',
            display: 'flex',
            gap: '0.75rem',
          }}
        >
          <AddIcon />
          Agregar especialista
        </Button>
      </Card>

      <DoctorModal
        open={isOpenDoctorModal}
        handleClose={closeDoctorModal}
        selectedDoctor={selectedDoctor}
      />
    </Box>
  )
}
export default DoctorsPage
