import { Stack, TextField } from '@mui/material'
import { SearchOutlined as SearchIcon } from '@mui/icons-material'
import { useBoolean } from 'hooks/useBoolean'

interface SearchBarProps {
  value: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchBar = ({ value, handleChange }: SearchBarProps) => {
  const { value: isFocused, setTrue, setFalse } = useBoolean()

  return (
    <Stack flexDirection="row" sx={{ gap: '1rem' }}>
      <TextField
        sx={{ flex: 2, '& .MuiInputBase-input': { paddingInline: '0.5rem' } }}
        InputProps={{
          startAdornment: (
            <SearchIcon color={isFocused ? 'primary' : 'disabled'} />
          ),
          // TODO: Add a reset button functionality
          // endAdornment: (
          //   <IconButton tooltip="Clear field" icon={<ResetIcon />} />
          // ),
        }}
        // TODO: Able generic placeholder
        placeholder="Búsqueda por nombre o especialidad"
        type="text"
        fullWidth
        value={value}
        onChange={handleChange}
        onFocus={setTrue}
        onBlur={setFalse}
      />
    </Stack>
  )
}
export default SearchBar
