import { GridValidRowModel } from "@mui/x-data-grid";

const normalizeRows = (rows: GridValidRowModel[], keyId: string) => {
  const normalizedRows = rows.map((row) => {
    const { [keyId]: id, ...rest } = row;

    return {
      id,
      ...rest,
    };
  });

  return normalizedRows;
};

export { normalizeRows };
