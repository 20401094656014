import {
  IconButtonProps as MuiIconButtonProps,
  IconButton as MuiIconButton,
  Tooltip,
} from '@mui/material'

interface IconButtonProps extends MuiIconButtonProps {
  tooltip: string
  icon: JSX.Element
}

function IconButton({ tooltip, icon, ...delegated }: IconButtonProps) {
  return (
    <Tooltip title={tooltip}>
      <MuiIconButton {...delegated}>{icon}</MuiIconButton>
    </Tooltip>
  )
}
export default IconButton
