import { Dispatch, SetStateAction, useCallback, useState } from 'react'

interface ReturnType {
  value: boolean
  setValue: Dispatch<SetStateAction<boolean>>
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
}

const useBoolean = (initialValue?: boolean): ReturnType => {
  const [value, setValue] = useState(Boolean(initialValue))

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue((prev) => !prev), [])

  return {
    value,
    setValue,
    setTrue,
    setFalse,
    toggle,
  }
}

export { useBoolean }
